/*
 *'use strict';
 */

import 'core-js/es/object';
import 'core-js/es/typed-array';
import 'core-js/es/object';
import 'core-js/es/set';
import { createRoot } from 'react-dom/client';

import React from 'react';
import ReactDom from 'react-dom';
import { hot } from 'react-hot-loader/root';
import { Amplify, Auth } from 'aws-amplify';
import Cookies from 'universal-cookie';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import querystring from 'querystring';
import getAmplifyConfiguration from './amplifyConfiguration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Login.scss';

window.createReactRoot = createRoot;

let auth = getAmplifyConfiguration();

Amplify.configure({
    Auth: auth,
});

const MFATypes = {
    SMS: true, // if SMS enabled in your user pool
    TOTP: false, // if TOTP enabled in your user pool
    Optional: false, // if MFA is set to optional in your user pool
};

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            newPassword: '',
            showMFASetup: false,
            showMFAChallenge: false,
            showLoginForm: true,
            showForgotPassword: false,
            user: null,
            mfaCodeValue: '',
            loginSubmit: [],
            errorMessage: '',
            showSigningInScreen: false,
            showSSOLoginForm: false,
            ssoRedirectUri: '',
            verificationCode: '',
            message: '',
            showRequireNewPassword: false,
            searchingSSO: false,
            /*
             * Password validation comes from Cognito Requirements:
             * https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
             */
            password_validation: {
                button_disabled: true,
                length: {
                    valid: false,
                    regex: /^.{8,}$/,
                },
                capital: {
                    valid: false,
                    regex: /[A-Z]/,
                },
                number: {
                    valid: false,
                    regex: /[0-9]/,
                },
                special_character: {
                    valid: false,
                    regex: /[= + - ^ $ * . \[ \] { } ( ) ? " ! @ # % & \/ \ , > < ' : ; | _ ~ `]/,
                },
            },
        };
    }

    componentDidMount() {
        let parsed = querystring.parse(window.location.hash.slice(1));

        if ('logout' in parsed) {
            Auth.signOut();
            window.location.href = '/login/logout';
            return;
        }

        const cookies = new Cookies();
        let cookiePrefix = `CognitoIdentityServiceProvider.SSOLogin.`;

        //when we come from SSO url is appended with access_token fragment
        if (parsed.hasOwnProperty('access_token')) {
            let cookie_settings = Auth._storage;
            delete cookie_settings.expires;
            cookie_settings.maxAge = parsed.expires_in;

            cookies.set(cookiePrefix + `accessToken`, parsed.access_token, cookie_settings);
            cookies.set(cookiePrefix + `idToken`, parsed.id_token, cookie_settings);
            cookies.set('crossAppSigninNoRedirect', 'true', cookie_settings);

            this.sendTokenToBackend(parsed.access_token);

            return;
        }

        if (cookies.get(cookiePrefix + `accessToken`)) {
            this.sendTokenToBackend(cookies.get(cookiePrefix + `accessToken`));

            return;
        }

        Auth.currentSession()
            .then((data) => {
                this.sendTokenToBackend(data.accessToken.jwtToken, { autoLoginCheck: true });
            })
            .catch((error) => {});
    }

    checkSSO = (e) => {
        let data = new FormData();
        this.setState({ searchingSSO: true });

        let username = this.state.username.toLowerCase();

        if (username === '') {
            this.setState(
                {
                    searchingSSO: false,
                },
                () => {
                    this.displayScreen('showLoginForm');
                }
            );

            return;
        }

        fetch('/login/checkSSODomain?username=' + username, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'same-origin',
        }).then((response) => {
            if (!response.ok) {
                return; //We do not want to throw errors if this call fails
            }

            response.json().then((data) => {
                if (data.status === 'SUCCESS') {
                    data = data.data;
                    this.setState({
                        showLoginForm: false,
                        showSSOLoginForm: true,
                        ssoRedirectUri: data.redirectUri,
                        searchingSSO: false,
                    });
                } else {
                    if (data.error_constant === 'ORGANIZATION_NO_DIRECT_ACCOUNT_LOGIN') {
                        this.setState(
                            {
                                errorMessage: data.data.message,
                                searchingSSO: false,
                            },
                            () => {
                                this.displayScreen();
                            }
                        );
                    } else {
                        this.setState(
                            {
                                ssoRedirectUri: '',
                                searchingSSO: false,
                            },
                            () => {
                                this.displayScreen('showLoginForm');
                            }
                        );
                    }
                }
            });
        });
    };

    setUsername = (e) => {
        this.setState({
            username: e.target.value,
            searchingSSO: true,
        });
    };

    setPassword = (e) => {
        this.setState({
            password: e.target.value,
            searchingSSO: false,
        });
    };

    mfaCodeInput = (e) => {
        this.setState({
            mfaCodeValue: e.target.value,
        });
    };

    mfaChallenge = async (e) => {
        e.preventDefault();
        let mfaType = 'SMS_MFA';

        console.log(this.state.user);

        // If MFA is enabled, sign-in should be confirmed with the confirmation code
        try {
            let user = await Auth.confirmSignIn(
                this.state.user, // Return object from Auth.signIn()
                this.state.mfaCodeValue, // Confirmation code
                mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
            );

            user.challengeName = 'SMS_PASSED'; //Overriding SMS_MFA to SMS_PASSED if there is no error

            this.processSignInStep(user);
        } catch (err) {
            this.processCaughtError(err);
        }
    };

    changePasswordRequired = async (e) => {
        e.preventDefault();
        await Auth.completeNewPassword(
            this.state.user, // the Cognito User Object
            this.state.newPassword // the new password
        )
            .then((data) =>
                this.setState({ message: 'Password updated successfully. Please login with new password.' }, () => {
                    this.displayScreen('showLoginForm');
                })
            )
            .catch((err) => this.processCaughtError(err));
    };

    changePasswordInput = (e) => {
        let length = RegExp(this.state.password_validation.length.regex);
        let special_character = RegExp(this.state.password_validation.special_character.regex);
        let capital = RegExp(this.state.password_validation.capital.regex);
        let number = RegExp(this.state.password_validation.number.regex);

        let password_validation = this.state.password_validation;

        password_validation.capital.valid = capital.test(e.target.value);
        password_validation.special_character.valid = special_character.test(e.target.value);
        password_validation.length.valid = length.test(e.target.value);
        password_validation.number.valid = number.test(e.target.value);

        if (
            password_validation.capital.valid === true &&
            password_validation.special_character.valid === true &&
            password_validation.length.valid === true &&
            password_validation.number.valid === true
        ) {
            password_validation.button_disabled = false;
        } else {
            password_validation.button_disabled = true;
        }

        this.setState({
            newPassword: e.target.value,
            password_validation: password_validation,
        });
    };

    changeVerificationCode = (e) => {
        e.preventDefault();
        this.setState({
            verificationCode: e.target.value,
        });
    };

    sendVerificationCode = (e) => {
        e.preventDefault();
        Auth.forgotPassword(this.state.username.toLowerCase()).catch((err) => console.log(err));
    };

    changePassword = async (e) => {
        e.preventDefault();
        try {
            Auth.forgotPasswordSubmit(
                this.state.username.toLowerCase(),
                this.state.verificationCode,
                this.state.newPassword
            )
                .then((data) =>
                    this.setState({ message: 'Password updated successfully. Please login with new password.' }, () => {
                        this.displayScreen('showLoginForm');
                    })
                )
                .catch((err) => this.processCaughtError(err));
        } catch (err) {
            this.processCaughtError(err);
        }
    };

    sendTokenToBackend(jwt, additional = null) {
        this.displayScreen('showSigningInScreen');

        try {
            let data = new FormData();
            data.append('accessToken', jwt);

            let csrf = document.querySelector('meta[name="csrf-token"]').content;
            data.append('csrfToken', csrf);

            // Check if additional data exists, and append each key-value pair
            if (additional) {
                Object.entries(additional).forEach(([key, value]) => {
                    data.append(key, value);
                });
            }

            fetch('/signin/verify', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                body: data,
                credentials: 'same-origin',
            }).then((response) => {
                if (!response.ok) {
                    response.json().then((data) => {
                        if (data.error_constant === 'CSRF_EXPIRED') {
                            window.location.href = window.location.href;
                        }
                    });
                    this.setState({
                        showSigningInScreen: false,
                        showLoginForm: true,
                        showSSOLoginForm: false,
                        searchingSSO: false,
                    });
                    throw Error(response.statusText);
                }

                response.json().then((data) => {
                    if (data.status === 'SUCCESS') {
                        data = data.data;
                        window.location.href = data.redirectUri;
                        //remove redirect cookie if this is the last step in the signin
                        if (data.redirectUri != '/login/account_selector') {
                            const cookies = new Cookies();
                            cookies.remove('crossAppSigninNoRedirect', Auth._storage);
                        }
                    } else {
                        this.setState({
                            showSigningInScreen: false,
                            errorMessage: data.data.message,
                        });
                    }
                });
            });
        } catch (err) {
            console.log(err);
        }
    }

    processSignInStep(user = null) {
        if (user === null) {
            return false;
        }

        if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            this.displayScreen('showMFAChallenge');
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.displayScreen('showRequireNewPassword');
        } else if (user.challengeName === 'MFA_SETUP') {
            this.displayScreen('showMFASetup');
        } else {
            let jwt = user.signInUserSession.accessToken.getJwtToken();
            //set cookie here without the '%' symbol which causes an error
            const cookies = new Cookies();
            let cookie_settings = Auth._storage;
            delete cookie_settings.expires;

            let cookiePrefix = `CognitoIdentityServiceProvider.`;

            cookies.set(cookiePrefix + `idToken`, user.signInUserSession.idToken.getJwtToken(), cookie_settings);
            cookies.set('crossAppSigninNoRedirect', 'true', cookie_settings);

            // getCSRF();

            this.sendTokenToBackend(jwt);
        }
    }

    processCaughtError(err) {
        let message = '';

        if (err.code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
            message = 'Username and Password do not match!';
        } else if (err.code === 'PasswordResetRequiredException') {
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
            this.displayScreen('showForgotPassword');
            return;
            //   message = 'Your password must be reset. Please click <a href="/forgot">here</a> to reset it.'
        } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
            message = 'Username and Password do not match!';
        } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            message = 'Username and Password do not match!';
        } else if (err.code === 'CodeMismatchException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            message = 'Code provided is invalid. Please request a code again.';
        } else if (err.code === 'ExpiredCodeException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            message = 'Code provided is invalid. Please request a code again.';
        } else if (err.code === 'LimitExceededException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            message = 'You have exceeded the number of times to login. Please try again after a few minutes.';
        } else if (err.code === 'MissingRequirements') {
            //The error happens when the username/email or password field is completely empty.
            message = 'Both username and password are required.';
        } else {
            message = 'There was an error';
            console.log(err);
        }
        this.setState(
            {
                errorMessage: message,
            },
            () => {
                this.displayScreen();
            }
        );
    }

    displayScreen(form = null) {
        let screens = {
            showMFASetup: false,
            showMFAChallenge: false,
            showLoginForm: false,
            showForgotPassword: false,
            showSigningInScreen: false,
            showSSOLoginForm: false,
            showRequireNewPassword: false,
        };

        if (form === 'showSigningInScreen') {
            screens.message = '';
            screens.errorMessage = '';
        }

        if (form) {
            screens[form] = true;
        }
        this.setState(screens);
    }

    signIn = async (e) => {
        e.preventDefault();

        const hostname = window && window.location && window.location.hostname;

        if (
            this.state.showSSOLoginForm === false &&
            (this.state.username === null ||
                this.state.username === '' ||
                this.state.password === null ||
                this.state.password === '')
        ) {
            //If the username/email or password fields are empty, throw an error, otherwise continue.
            let err = [];
            err.code = 'MissingRequirements';
            this.processCaughtError(err);

            return;
        }

        if (this.state.ssoRedirectUri === '') {
            Auth.signOut();
            let context_host = hostname;
            if (context_host.indexOf('hbu.co') != -1) {
                context_host = 'api-' + context_host;
            } else if (context_host.indexOf('prep.openbeds.net') != -1) {
                //if accessed via prep.openbeds.net needs to set context host to api-state.ob.prep.hbu.co
                //extract out the state, it is the first substring before the "."
                context_host = 'api-' + context_host.split('.')[0] + '.ob.prep.hbu.co';
            } else {
                context_host = 'internal-' + context_host;
            }
            await Auth.signIn(this.state.username.toLowerCase(), this.state.password, { context_host: context_host })
                .then((user) => {
                    this.setState({ user: user }, () => {
                        this.processSignInStep(this.state.user);
                    });
                })
                .catch((err) => {
                    this.processCaughtError(err);
                });
        } else {
            window.location.href = this.state.ssoRedirectUri;
        }
    };

    render() {
        return (
            <>
                {this.state.message !== '' ? (
                    <>
                        <div className="alert alert-success">{this.state.message}</div>
                    </>
                ) : (
                    <></>
                )}
                {this.state.errorMessage !== '' ? (
                    <>
                        <div className="alert alert-danger">{this.state.errorMessage}</div>
                        <div className="d-grid gap-2">
                            <Button
                                id="btnReturnToLogin"
                                size="lg"
                                type="submit"
                                onClick={() => {
                                    this.setState({
                                        showLoginForm: true,
                                        errorMessage: '',
                                    });
                                }}
                            >
                                Go Back to Login Screen
                            </Button>
                        </div>
                    </>
                ) : (
                    <></>
                )}

                {this.state.showLoginForm ? (
                    <Form>
                        <Form.Group size="lg" controlId="inputUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                autoFocus
                                type="email"
                                value={this.state.username}
                                onChange={this.setUsername}
                                onBlur={this.checkSSO}
                            />
                        </Form.Group>
                        <Form.Group size="lg" className="form-group mb-3" controlId="inputPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={this.state.password} onChange={this.setPassword} />
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button
                                id="btnLogin"
                                size="lg"
                                type="submit"
                                onClick={this.signIn}
                                disabled={this.state.searchingSSO}
                            >
                                Login
                            </Button>
                        </div>
                    </Form>
                ) : (
                    <></>
                )}
                {this.state.showSSOLoginForm ? (
                    <Form>
                        <Form.Group size="lg" className="form-group mb-3" controlId="inputUsernameSso">
                            <Form.Label>Username</Form.Label>

                            <Form.Control
                                type="email"
                                value={this.state.username}
                                onChange={this.setUsername}
                                onBlur={this.checkSSO}
                            />
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button
                                id="btnLoginSso"
                                size="lg"
                                type="submit"
                                onClick={this.signIn}
                                disabled={this.state.searchingSSO}
                            >
                                Login Through Your Organization
                            </Button>
                        </div>
                    </Form>
                ) : (
                    <></>
                )}
                {this.state.showRequireNewPassword ? (
                    <>
                        <b>You are required to change your password</b>
                        <br />
                        Please provide a new password below:
                        <br />
                        <br />
                        <Form>
                            <Form.Group size="lg" className="form-group" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={this.newPassword}
                                    onChange={this.changePasswordInput}
                                />
                            </Form.Group>
                            <div>
                                Passwords are required to meet the following requirements:
                                <ul style={{ 'list-style-type': 'none' }}>
                                    <li>
                                        {this.state.password_validation.length.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 8 characters.
                                    </li>
                                    <li>
                                        {this.state.password_validation.capital.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 1 capitalized letter.
                                    </li>
                                    <li>
                                        {this.state.password_validation.number.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 1 number.
                                    </li>
                                    <li>
                                        {this.state.password_validation.special_character.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 1 special character (!#$ etc.)
                                    </li>
                                </ul>
                            </div>
                            <div className="d-grid gap-2">
                                <Button
                                    size="lg"
                                    type="submit"
                                    onClick={this.changePasswordRequired}
                                    disabled={this.state.password_validation.button_disabled}
                                >
                                    Change Password
                                </Button>
                            </div>
                        </Form>
                    </>
                ) : (
                    <></>
                )}
                {this.state.showForgotPassword ? (
                    <>
                        <b>Change your password</b>
                        <br />
                        Please click the button below to send yourself a verification code. Please provide it and a new
                        password to reset your password.
                        <div className="d-grid gap-2">
                            <Button size="sm" type="submit" onClick={this.sendVerificationCode}>
                                Send Verification Code
                            </Button>
                            <br />
                            <br />
                        </div>
                        <Form>
                            <Form.Group size="lg" className="form-group" controlId="username">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="email"
                                    value={this.state.username}
                                    onChange={this.setUsername}
                                />
                            </Form.Group>
                            <Form.Group size="lg" className="form-group" controlId="password">
                                <Form.Label>Verification Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={this.verificationCode}
                                    onChange={this.changeVerificationCode}
                                />
                            </Form.Group>
                            <Form.Group size="lg" className="form-group" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={this.newPassword}
                                    onChange={this.changePasswordInput}
                                />
                            </Form.Group>
                            <div>
                                Passwords are required to meet the following requirements:
                                <ul style={{ 'list-style-type': 'none' }}>
                                    <li>
                                        {this.state.password_validation.length.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 8 characters.
                                    </li>
                                    <li>
                                        {this.state.password_validation.capital.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 1 capitalized letter.
                                    </li>
                                    <li>
                                        {this.state.password_validation.number.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 1 number.
                                    </li>
                                    <li>
                                        {this.state.password_validation.special_character.valid ? (
                                            <FontAwesomeIcon icon="fa fa-check" className="green" />
                                        ) : (
                                            <FontAwesomeIcon icon="fa fa-xmark" className="red" />
                                        )}
                                        Your password must have at least 1 special character (!#$ etc.)
                                    </li>
                                </ul>
                            </div>
                            <div className="d-grid gap-2">
                                <Button
                                    size="lg"
                                    type="submit"
                                    onClick={this.changePassword}
                                    disabled={this.state.password_validation.button_disabled}
                                >
                                    Change Password
                                </Button>
                            </div>
                        </Form>
                    </>
                ) : (
                    <></>
                )}
                {this.state.showMFAChallenge ? (
                    <Form>
                        <Form.Group size="lg" className="form-group" controlId="username">
                            <Form.Label>
                                Please provide the authentication code sent to the phone number on the account
                            </Form.Label>
                            <Form.Control
                                autoFocus
                                type="text"
                                value={this.state.mfaCodeValue}
                                onChange={this.mfaCodeInput}
                            />
                            <br />
                            <div className="d-grid gap-2">
                                <Button size="lg" type="submit" onClick={this.mfaChallenge}>
                                    Confirm
                                </Button>
                            </div>
                        </Form.Group>
                    </Form>
                ) : (
                    <></>
                )}
                {this.state.showSigningInScreen ? (
                    <>
                        <div className="alert alert-success">Logging In...</div>
                        <br />
                        <br />
                    </>
                ) : (
                    ''
                )}
            </>
        );
    }
}

export default Login;
