function getAmplifyConfiguration() {
    const hostname = window && window.location && window.location.hostname;
    /*
    This should contain production pool information.
    */

    let AmplifyConfiguration = {
        region: 'us-east-1',
        userPoolId: 'us-east-1_43Rcqm7mi',
        userPoolWebClientId: 'l4qvk4630eaf72cbid1rg689j',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        cookieStorage: {
            domain: 'openbeds.net',
            secure: true,
            path: '/',
            expires: 365,
        },
    };

    if (hostname.includes('.dev.')) {
        AmplifyConfiguration.userPoolId = 'us-east-1_26fkOVwna';
        AmplifyConfiguration.userPoolWebClientId = '533k2ovktrqec9v95vsdtsp2uu';
        AmplifyConfiguration.cookieStorage.domain = 'ob.dev.hbu.co';
    }

    if (hostname.includes('10.0.2.2') || hostname.includes('.qa.') || !hostname.includes('.')) {
        AmplifyConfiguration.userPoolId = 'us-east-1_0o2u1ob5Y';
        AmplifyConfiguration.userPoolWebClientId = '59e16sbp52glua9mlam28ov80a';
        AmplifyConfiguration.cookieStorage.domain = 'ob.qa.hbu.co';
    }

    if (hostname.includes('localhost') || !hostname.includes('.')) {
        AmplifyConfiguration.cookieStorage.domain = hostname;
    }

    if (hostname.includes('.prep.')) {
        AmplifyConfiguration.userPoolId = 'us-east-1_cpSbfkXsl';
        AmplifyConfiguration.userPoolWebClientId = '3ui92n0b5q7sfv3g73lh020jdq';
        AmplifyConfiguration.cookieStorage.domain = 'prep.openbeds.net';
    }

    if (hostname === 'demo.openbeds.net') {
        AmplifyConfiguration.userPoolId = 'us-east-1_cpSbfkXsl';
        AmplifyConfiguration.userPoolWebClientId = '3ui92n0b5q7sfv3g73lh020jdq';
        AmplifyConfiguration.cookieStorage.domain = 'openbeds.net';
    }

    return AmplifyConfiguration;
}

export default getAmplifyConfiguration;
