/*
 *'use strict';
 */

import 'core-js/es/object';
import 'core-js/es/typed-array';
import 'core-js/es/object';
import 'core-js/es/set';

import React, { useState, useEffect } from 'react';

const UserAccountAction = (props) => {
    const uniqueHeadingId = `headingUserAccountRow${Math.floor(Math.random() * 1000000)}`;
    const btnId = props.uniqueID || `btnUserAccount${Math.floor(Math.random() * 1000000)}`;
    const [showStatusMessage, setShowStatusMessage] = useState(true);

    const messageClass = props.statusMessage?.status === 'error' ? 'text-danger' : 'text-success';

    const hideStatusMessage = () => {
        setShowStatusMessage(false);
    };

    useEffect(() => {
        setShowStatusMessage(true);
    }, props.statusMessage);

    return (
        <>
            <div className=" my-2">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div id={`${uniqueHeadingId}`} className="left">
                        <div className="h6 fw-bold mb-1">{props.title}</div>
                        <div>{props.subTitle}</div>
                    </div>
                    <div className="right">
                        <button
                            id={`${btnId}`}
                            disabled={props.isDisabled}
                            type="button"
                            className="btn btn-outline-primary"
                            aria-describedby={`${uniqueHeadingId}`}
                            style={{ width: '14rem', maxWidth: '100%' }}
                            onClick={props.handleClick}
                        >
                            {props.buttonLabel}
                        </button>
                    </div>
                </div>
                {showStatusMessage && props.statusMessage?.message && (
                    <div className="d-flex flex-wrap align-items-center">
                        <div className={messageClass}>{props.statusMessage.message}</div>
                        <button className="btn btn-link" type="button" onClick={hideStatusMessage}>
                            Hide message
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default UserAccountAction;
